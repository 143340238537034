import { Component, HostListener, inject, input, OnInit, output, AfterViewInit, OnDestroy } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule} from "@angular/material/checkbox";
import { Credentials, OAuthType } from "./credentials";

import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

export interface CredentialsWithRememberMe extends Credentials {
    rememberMe?: boolean;
}

@Component({
    selector: "glotto-credentials",
    standalone: true,
    imports: [
      MatButtonModule,
      MatFormFieldModule,
      MatInputModule,
      MatDividerModule,
      MatIconModule,
      MatCheckboxModule,
      FormsModule,
      ReactiveFormsModule,
      TranslatePipe
  ],
    styles: [`
        :host {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        mat-form-field {
            width: 100%;
        }

        mat-divider {
            margin: 40px 0 20px 0; 
        }   

        .email {
            margin-bottom: 20px;
        }

        .credentials-btn {
            width: 100%; 
            margin-top: 20px;
            min-height: 56px;
        }

        .credentials-alternatives {
            display: flex; 
            justify-content: center;
        }

        .credentials-need-an-account {
            margin-top: 20px;
        }

        .credentials-remember-me {
            display: flex;
        }
    `],
    template: `
    <form class="credentials">
      <mat-form-field appearance="outline" class="email">
        <mat-label>{{ 'auth.email' | translate }}</mat-label>
        <input matInput
          placeholder="{{ 'auth.email' | translate }}"
          type="email"
          [formControl]="email"
          required>
        @if (email.invalid) {
          <mat-error>{{getEmailErrorMessage()}}</mat-error>
        }
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'auth.password' | translate }}</mat-label>
        <input matInput
          placeholder="{{ 'auth.password' | translate }}"
          [type]="hidePassword ? 'password' : 'text'"
          [formControl]="password"
          required>
    
        <span matSuffix style="margin-right: 10px">
          <button mat-icon-button
            (click)="hidePassword = !hidePassword"
            [attr.aria-label]="'auth.hidePassword' | translate"
            [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
    
          @if (capsLockOn) {
            <mat-icon style="margin-right: 10px">keyboard_capslock</mat-icon>
          }
        </span>
    
        @if (password.invalid) {
          <mat-error>{{getPasswordErrorMessage()}}</mat-error>
        }
      </mat-form-field>
    
      @if (type() === 'sign-in') {
        <div class="credentials-remember-me">
          <mat-checkbox color="accent" [checked]="rememberMe" (change)="rememberMe != rememberMe">{{ 'auth.rememberMe' | translate }}</mat-checkbox>
        </div>
      }
    
    
      <button mat-flat-button
        color="accent"
        class="credentials-btn"
        (click)="submit()"
        [disabled]="password.invalid || email.invalid">
        @if (type() === 'sign-in') {
          <span>{{ 'auth.signIn' | translate }}</span>
        } @else {
          <span>{{ 'auth.signUp' | translate }}</span>
        }
      </button>
    
      @if (type() === 'sign-in') {
        <p style="text-align: right; margin-top: 10px;">
          <a (click)="resetPassword.emit()">{{ 'auth.forgotPassword' | translate }}</a>
        </p>
      
        <div style="position: relative; width: 100%;">
          <mat-divider></mat-divider>
          <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
            <div style="display: flex; text-align: center; padding: 10px; background: white; border-radius: 20px;">
              <span>{{ 'auth.or' | translate }}</span>
            </div>
          </div>
        </div>
      
        <div class="credentials-alternatives">
          <div id="googleButton"></div>
        </div>
      }
    </form>
    `,
})
export class CredentialsFormComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly type = input.required<"sign-in" | "sign-up">();
    
    readonly credentials = output<CredentialsWithRememberMe>();
    readonly oauth = output<OAuthType>();
    readonly resetPassword = output<void>();
    
    @HostListener('window:keydown', ['$event'])
    @HostListener('window:keyup', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const capsOn = event.getModifierState && event.getModifierState('CapsLock');
        this.capsLockOn = capsOn;
    }

    private readonly translate = inject(TranslateService);

    rememberMe = true;
    hidePassword = true;
    capsLockOn = false;
    oAuthType = OAuthType;

    email = new FormControl('', [Validators.required, Validators.email]);
    password = new FormControl('', [Validators.required, Validators.minLength(8)]);

    private buttonRenderAttempts = 0;
    private maxRenderAttempts = 3;
    private renderInterval?: number;

    ngOnInit(): void {
        try {
            const buttonElement = document.getElementById("googleButton");
            if (buttonElement) {
                (window as any).google?.accounts.id.renderButton(
                    buttonElement,
                    { 
                      theme: "outline", 
                      size: "large",
                      type: 'standard',
                      shape: 'rectangular',
                      text: 'signin_with',
                      logo_alignment: 'left'
                  }
              );
            }
        } catch (error) {
            console.error('Failed to render Google button:', error);
        }
    }

    ngAfterViewInit(): void {
        this.renderGoogleButton();
    }

    ngOnDestroy(): void {
        if (this.renderInterval) {
            window.clearInterval(this.renderInterval);
        }
    }

    private renderGoogleButton(): void {
        const tryRenderButton = () => {
            try {
                const buttonElement = document.getElementById("googleButton");
                if (buttonElement && (window as any).google?.accounts?.id) {
                    (window as any).google.accounts.id.renderButton(
                        buttonElement,
                        { 
                            theme: "outline", 
                            size: "large",
                            type: 'standard',
                            shape: 'rectangular',
                            text: 'signin_with',
                            logo_alignment: 'left'
                        }
                    );
                    
                    if (this.renderInterval) {
                        window.clearInterval(this.renderInterval);
                    }
                    return true;
                }
            } catch (error) {
                console.error('Failed to render Google button:', error);
            }
            return false;
        };

        // Try to render immediately
        if (!tryRenderButton()) {
            // If immediate render fails, try a few more times
            this.renderInterval = window.setInterval(() => {
                this.buttonRenderAttempts++;
                if (tryRenderButton() || this.buttonRenderAttempts >= this.maxRenderAttempts) {
                    window.clearInterval(this.renderInterval);
                }
            }, 1000); // Try every second
        }
    }

    submit() {
        if (this.email.valid && this.password.valid) {
            this.credentials.emit({
                email: this.email.value!,
                password: this.password.value!,
                rememberMe: this.rememberMe,
            });
        }
    }

    getEmailErrorMessage() {
        if (this.email.hasError('required')) {
            return this.translate.instant('auth.youMustEnterAValue');
        }
    
        return this.email.hasError('email') ? this.translate.instant('auth.notAValidEmail') : '';
    }

    getPasswordErrorMessage() {
        if (this.password.hasError('required')) {
            return this.translate.instant('auth.youMustEnterAValue');
        }
    
        return this.password.hasError('minlength') ? this.translate.instant('auth.passwordMustBeAtLeast8CharactersLong') : '';
    }
}