import { HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ExceptionEndpoint {
  url: string;
  skipKeys: string[];
}



const isValidRequestForInterceptor = (requestUrl: string, urlsToIgnore: string[]): boolean => {
  let positionIndicator = 'api/';
  let position = requestUrl.indexOf(positionIndicator);

  if (position > 0) {
    
    let destination = requestUrl.substr(position + positionIndicator.length);
    for (let address of urlsToIgnore) {
      if (new RegExp(address).test(destination)) {
        return false;
      }
    }
  }

  return true;
}

const mapKeys = (json: any, skipKeys: string[] = []): any => {
  const toCamel = (key: string) => {
    const camelized = key.replace(/([-_][a-z])/gi, arg => arg.toUpperCase().replace('-', '').replace('_', ''));

    if (camelized.length > 0) {
      return `${camelized[0].toLocaleLowerCase()}${camelized.slice(1)}`;
    }
    
    return camelized;
  };

  if (Array.isArray(json)) {
    return json.map(v => mapKeys(v, skipKeys));
  } else if (json != null && json.constructor === Object) {
    return Object.keys(json).reduce((destination: any, key) => {
      skipKeys.includes(key)
        ? (destination[toCamel(key)] = json[key])
        : (destination[toCamel(key)] = mapKeys(json[key], skipKeys));
      return destination;
    }, {});
  } else {
    return json;
  }
}

const urlsToIgnore = ['i18n/.*/'];
const endpointException: ExceptionEndpoint[] = [
  {
    url: 'master_catalog/v/.*/articles',
    skipKeys: ['user_defined_extensions'],
  },
];



export const responseToCamelCaseInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (!isValidRequestForInterceptor(req.url, urlsToIgnore)) {
      return next(req);
    }

    return next(req.clone()).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          const exceptionUrl = endpointException.find(item => new RegExp(item.url).test(req.url));
          return event.clone({
            body: mapKeys(event.body, exceptionUrl ? exceptionUrl.skipKeys : []),
          });
        }

        return event;
      })
    );
  }
