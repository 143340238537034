import { createAction, props } from '@ngrx/store';
import { CredentialsWithRememberMe } from '../credentials-form.component';
import { User } from '../models/user';
import { Credentials } from '../credentials';


export const auth = createAction(
    '[Auth] Auth'
);

export const authSuccess = createAction(
    '[Auth] Auth Success',
    props<{ user: User }>()
);

export const authFailure = createAction(
    '[Auth] Auth Failure',
    props<{ error: Error }>()
);

export const signIn = createAction(
    '[Auth] Sign In',
    props<{ credentials: CredentialsWithRememberMe }>()
);

export const signInSuccess = createAction(
    '[Auth] Sign In Success',
    props<{ user: User }>()
);

export const signInFailure = createAction(
    '[Auth] Sign In Failure',
    props<{ error: Error }>()
);

export const signUp = createAction(
    '[Auth] Sign Up',
    props<{ credentials: Credentials }>()
);

export const signUpSuccess = createAction(
    '[Auth] Sign Up Success',
    props<{ user: User }>()
);

export const signUpFailure = createAction(
    '[Auth] Sign Up Failure',
    props<{ error: Error }>()
);

export const signOut = createAction(
    '[Auth] Sign Out'
);

export const googleSignIn = createAction(
    '[Auth] Google Sign In',
    props<{ token: string }>()
);