import { Injectable } from '@angular/core';
import { BaseService } from './networking/services/base-service';
import { Credentials } from './auth/credentials';
import { User } from './auth/models/user';
import { Observable, tap } from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class AuthService extends BaseService {
	me() {
		return this.http.get<User>(`${this.baseUrl}/auth/me/`);
	}

	signIn(credentials: Credentials) {
		const { email, password } = credentials;
		
		return this.http.post<User>(`${this.baseUrl}/auth/sign-in/`, {
			username: email, 
			password
		});
	}

	signUp(credentials: Credentials) {
		const { email, password } = credentials;
		return this.http.post<User>(`${this.baseUrl}/auth/sign-up/`, {
			username: email,
			email,
			password
		});
	}

	public googleSignIn(token: string): Observable<{ user: User; token: string }> {
        return this.http.post<{ user: User; token: string }>(
            `${this.baseUrl}/auth/google/`,
            { token }
        );
    }
}

