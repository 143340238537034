import { Component, inject, OnDestroy, OnInit, DestroyRef } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { RouterLinkWithHref } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { MatSnackBarModule, MatSnackBar } from "@angular/material/snack-bar";

import { CredentialsFormComponent, CredentialsWithRememberMe } from "./credentials-form.component";
import { OAuthType } from "./credentials";
import * as AuthActions from "./state/auth.actions";
import { LogoComponent } from "../logo/logo.component";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import * as AuthSelectors from "./state/auth.reducer";
import { EnvironmentService } from "../networking/services/environment.service";


@Component({
    selector: "glotto-login",
    standalone: true,
    imports: [
        MatCardModule,
        MatSnackBarModule,
        RouterLinkWithHref,
        LogoComponent,
        CredentialsFormComponent,
        TranslatePipe,
    ],
    styles: [`
        @use 'src/styles/mixins' as mixins;

        :host {
            @include mixins.flex-column-container();

            justify-content: center;
            align-items: center;
        }

        .sign-in {
            max-width: 500px;
            
            mat-card {
                background-color: var(--surface);
                border-radius: var(--rounded-corners);
            }

            mat-card-header {
                justify-content: center;
            }
        }

        .login-alternatives {
            display: flex; 
            justify-content: center;
        }

        .login-need-an-account {
            text-align: center;
        }

    `],
    template: `

    <div class="sign-in">
        <glotto-logo />
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-subtitle>
                    <h3>{{ 'landing.welcome' | translate }}</h3>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <glotto-credentials
                    [type]="'sign-in'"
                    (oauth)="onOAuth($event)"
                    (credentials)="onSubmit($event)"/>

                <div class="login-need-an-account">
                    <p>{{ 'landing.needAnAccount' | translate }} <a style="color: inherit" routerLink="/sign-up">{{ 'landing.signUp' | translate }}</a></p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    `,
})
export class SignInComponent implements OnInit, OnDestroy {
    private readonly store = inject(Store);
    private readonly snackBar = inject(MatSnackBar);
    private readonly environment = inject(EnvironmentService);
    private readonly destroyRef = inject(DestroyRef);

    ngOnInit(): void {
        this.store.select(AuthSelectors.userError).pipe(
            filter(error => !!error),
            map(error => error.message),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(error => {
            this.snackBar.open(error as string, 'Close', { duration: 3000 });
        });

        // Initialize Google OAuth
        this.initializeGoogleSignIn();
    }

    ngOnDestroy(): void {
        // Clean up Google Sign-In
        (window as any).google?.accounts.id.cancel();
    }

    private initializeGoogleSignIn(): void {
        const clientId = this.environment.resolve().googleClientId;
        console.log('Initializing Google Sign-In with client ID:', clientId);

        try {
            (window as any).google?.accounts.id.initialize({
                client_id: clientId,
                callback: this.handleGoogleSignIn.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true,
                context: 'signin',
                itp_support: true,
                error_callback: (error: any) => {
                    console.error('Google Sign-In Error:', error);
                }
            });
            console.log('Google Sign-In initialized successfully');
        } catch (error) {
            console.error('Failed to initialize Google Sign-In:', error);
        }
    }

    public onSubmit(credentials: CredentialsWithRememberMe): void {
        this.store.dispatch(AuthActions.signIn({ credentials }));
    }

    public onOAuth(type: OAuthType): void {
        if (type === 'google') {
            // (window as any).google?.accounts.id.prompt();
        }
    }

    private handleGoogleSignIn(response: any): void {
        console.log('Google Sign-In response:', response);
        if (response.credential) {
            console.log('Got credential, dispatching action');
            this.store.dispatch(AuthActions.googleSignIn({ 
                token: response.credential 
            }));
        } else {
            console.error('No credential in response:', response);
            this.snackBar.open('Google sign-in failed', 'Close', { 
                duration: 3000 
            });
        }
    }
}
