import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { AuthService } from "src/app/auth.service";
import { LocalStorageService } from "src/app/local-storage.service";

import * as AuthActions from './auth.actions';
import * as ThemeActions from '../../theme/theme.actions';

@Injectable()
export class AuthEffects implements OnInitEffects {
    private readonly actions$ = inject(Actions);
    private readonly router = inject(Router);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly authService = inject(AuthService);
    
    ngrxOnInitEffects(): Action {
        return AuthActions.auth()
    }

    init$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.auth),
        exhaustMap(() => this.authService
            .me()
            .pipe(
                map(user => AuthActions.authSuccess({ user })),
                catchError(error => of(AuthActions.authFailure({ error })))
            ),
        ),
    ));

    signIn$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signIn),
        exhaustMap(({ credentials }) => this.authService
            .signIn(credentials)
            .pipe(
                tap(user => {
                    if (!!credentials.rememberMe) {
                        this.localStorageService.set(LocalStorageService.authToken, user.token);
                    }
                }),
                map(user => AuthActions.signInSuccess({ user })),
                catchError(error => of(AuthActions.signInFailure({ error })))
            ),
        ),
    ));

    signUp$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signUp),
        exhaustMap(({ credentials }) => this.authService
            .signUp(credentials)
            .pipe(
                tap(user => this.localStorageService.set(LocalStorageService.authToken, user.token)),
                map(user => AuthActions.signUpSuccess({ user })),
                catchError(error => of(AuthActions.signUpFailure({ error })))
            ),
        ),
    ));

    resetTheme$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signOut, AuthActions.signUpSuccess, AuthActions.signInSuccess),
        map(() => ThemeActions.toggleTheme(false)),
    ));

    authFailure$ = createEffect(() => this.actions$.pipe(
        ofType( AuthActions.signInFailure, AuthActions.signUpFailure),
        map(({ error }) => AuthActions.authFailure({ error })),
    ));

    authSuccessSetToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signInSuccess, AuthActions.signUpSuccess),
        map(({ user }) => AuthActions.authSuccess({ user })),
    ));

    clearToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.authFailure, AuthActions.signOut),
        tap(() => this.localStorageService.remove(LocalStorageService.authToken)),
    ), { dispatch: false });
    

    // MARK: Routing
    navigateToSignIn$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signOut, AuthActions.authFailure),
        tap(() => {
            this.router.navigate(['sign-in']);
        }),
    ), { dispatch: false });

    navigateToHome$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.signInSuccess, AuthActions.signUpSuccess),
        tap(() => {
            this.router.navigate(['courses']);
        }),
    ), { dispatch: false });

    googleSignIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.googleSignIn),
            exhaustMap(({ token }) =>
                this.authService.googleSignIn(token).pipe(
                    tap(response => {
                        this.localStorageService.set(
                            LocalStorageService.authToken, 
                            response.token
                        );
                    }),
                    map(response => AuthActions.signInSuccess({ user: response.user })),
                    catchError(error => of(AuthActions.signInFailure({ error })))
                )
            )
        );
    });
}